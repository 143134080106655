const { CONSTANTS } = require("../constants");

module.exports = {
  onRouteUpdate: ({ location, previousLocation }) => {
    if (!previousLocation && !location.pathname.startsWith('/login')) {
      if (location.pathname !== '/') {
        localStorage.setItem(CONSTANTS.STORAGE_REDIRECT_KEY, location.pathname)
      } else {
        localStorage.removeItem(CONSTANTS.STORAGE_REDIRECT_KEY)
      }

      const searchParams = new URLSearchParams(location.search);
      if (searchParams.get('handoff')) {
        localStorage.setItem(CONSTANTS.STORAGE_HANDOFF_KEY, searchParams.get('handoff'))
      } else {
        localStorage.removeItem(CONSTANTS.STORAGE_HANDOFF_KEY)
      }

      window.location.replace('/login')
    }
  }
}