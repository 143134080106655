import React from 'react';
import ComponentCreator from '@docusaurus/ComponentCreator';

export default [
  {
    path: '/guidelines/doc-template',
    component: ComponentCreator('/guidelines/doc-template', '3f3'),
    exact: true
  },
  {
    path: '/guidelines/faq-template',
    component: ComponentCreator('/guidelines/faq-template', 'dd9'),
    exact: true
  },
  {
    path: '/guidelines/image-guidelines',
    component: ComponentCreator('/guidelines/image-guidelines', '85a'),
    exact: true
  },
  {
    path: '/guidelines/markdown-features',
    component: ComponentCreator('/guidelines/markdown-features', 'e0d'),
    exact: true
  },
  {
    path: '/login',
    component: ComponentCreator('/login', '574'),
    exact: true
  },
  {
    path: '/markdown-page',
    component: ComponentCreator('/markdown-page', 'c84'),
    exact: true
  },
  {
    path: '/releases',
    component: ComponentCreator('/releases', 'd27'),
    exact: true
  },
  {
    path: '/releases/2022/06/06/changelog',
    component: ComponentCreator('/releases/2022/06/06/changelog', '588'),
    exact: true
  },
  {
    path: '/releases/2022/06/20/changelog',
    component: ComponentCreator('/releases/2022/06/20/changelog', 'c38'),
    exact: true
  },
  {
    path: '/releases/2022/07/05/changelog',
    component: ComponentCreator('/releases/2022/07/05/changelog', '483'),
    exact: true
  },
  {
    path: '/releases/2022/07/25/changelog',
    component: ComponentCreator('/releases/2022/07/25/changelog', '1a4'),
    exact: true
  },
  {
    path: '/releases/2022/08/15/changelog',
    component: ComponentCreator('/releases/2022/08/15/changelog', 'bae'),
    exact: true
  },
  {
    path: '/releases/2022/09/05/changelog',
    component: ComponentCreator('/releases/2022/09/05/changelog', '51a'),
    exact: true
  },
  {
    path: '/releases/2022/09/26/changelog',
    component: ComponentCreator('/releases/2022/09/26/changelog', 'e07'),
    exact: true
  },
  {
    path: '/releases/2022/10/17/changelog',
    component: ComponentCreator('/releases/2022/10/17/changelog', '65d'),
    exact: true
  },
  {
    path: '/releases/2022/11/07/changelog',
    component: ComponentCreator('/releases/2022/11/07/changelog', 'ee6'),
    exact: true
  },
  {
    path: '/releases/2022/11/28/changelog',
    component: ComponentCreator('/releases/2022/11/28/changelog', '4b7'),
    exact: true
  },
  {
    path: '/releases/2022/12/19/changelog',
    component: ComponentCreator('/releases/2022/12/19/changelog', '7ce'),
    exact: true
  },
  {
    path: '/releases/2023/01/09/changelog',
    component: ComponentCreator('/releases/2023/01/09/changelog', '6d8'),
    exact: true
  },
  {
    path: '/releases/2023/01/30/changelog',
    component: ComponentCreator('/releases/2023/01/30/changelog', 'f05'),
    exact: true
  },
  {
    path: '/releases/2023/02/20/changelog',
    component: ComponentCreator('/releases/2023/02/20/changelog', 'f34'),
    exact: true
  },
  {
    path: '/releases/2023/03/13/changelog',
    component: ComponentCreator('/releases/2023/03/13/changelog', '41e'),
    exact: true
  },
  {
    path: '/releases/2023/04/03/changelog',
    component: ComponentCreator('/releases/2023/04/03/changelog', 'db4'),
    exact: true
  },
  {
    path: '/releases/2023/04/25/changelog',
    component: ComponentCreator('/releases/2023/04/25/changelog', 'd4e'),
    exact: true
  },
  {
    path: '/releases/2023/05/16/changelog',
    component: ComponentCreator('/releases/2023/05/16/changelog', 'a47'),
    exact: true
  },
  {
    path: '/releases/2023/06/05/changelog',
    component: ComponentCreator('/releases/2023/06/05/changelog', 'aee'),
    exact: true
  },
  {
    path: '/releases/archive',
    component: ComponentCreator('/releases/archive', 'cba'),
    exact: true
  },
  {
    path: '/releases/blog/RelNotes_CAPL_4.13-changelog',
    component: ComponentCreator('/releases/blog/RelNotes_CAPL_4.13-changelog', '1f3'),
    exact: true
  },
  {
    path: '/releases/page/2',
    component: ComponentCreator('/releases/page/2', 'c56'),
    exact: true
  },
  {
    path: '/releases/page/3',
    component: ComponentCreator('/releases/page/3', '3c3'),
    exact: true
  },
  {
    path: '/releases/page/4',
    component: ComponentCreator('/releases/page/4', '42a'),
    exact: true
  },
  {
    path: '/releases/page/5',
    component: ComponentCreator('/releases/page/5', '657'),
    exact: true
  },
  {
    path: '/releases/RelNotes_CAAS_20-changelog',
    component: ComponentCreator('/releases/RelNotes_CAAS_20-changelog', '386'),
    exact: true
  },
  {
    path: '/releases/RelNotes_CAAS_21-changelog',
    component: ComponentCreator('/releases/RelNotes_CAAS_21-changelog', '78a'),
    exact: true
  },
  {
    path: '/releases/RelNotes_CAAS_22-changelog',
    component: ComponentCreator('/releases/RelNotes_CAAS_22-changelog', 'bb0'),
    exact: true
  },
  {
    path: '/releases/RelNotes_CAPL_3.88-changelog',
    component: ComponentCreator('/releases/RelNotes_CAPL_3.88-changelog', '932'),
    exact: true
  },
  {
    path: '/releases/RelNotes_CAPL_3.89-changelog',
    component: ComponentCreator('/releases/RelNotes_CAPL_3.89-changelog', '1bb'),
    exact: true
  },
  {
    path: '/releases/RelNotes_CAPL_3.90-changelog',
    component: ComponentCreator('/releases/RelNotes_CAPL_3.90-changelog', '5a3'),
    exact: true
  },
  {
    path: '/releases/RelNotes_CAPL_3.91-changelog',
    component: ComponentCreator('/releases/RelNotes_CAPL_3.91-changelog', 'ab7'),
    exact: true
  },
  {
    path: '/releases/RelNotes_CAPL_3.92-changelog',
    component: ComponentCreator('/releases/RelNotes_CAPL_3.92-changelog', '152'),
    exact: true
  },
  {
    path: '/releases/RelNotes_CAPL_3.93-changelog',
    component: ComponentCreator('/releases/RelNotes_CAPL_3.93-changelog', '59e'),
    exact: true
  },
  {
    path: '/releases/RelNotes_CAPL_3.94-changelog',
    component: ComponentCreator('/releases/RelNotes_CAPL_3.94-changelog', '713'),
    exact: true
  },
  {
    path: '/releases/RelNotes_CAPL_3.95-changelog',
    component: ComponentCreator('/releases/RelNotes_CAPL_3.95-changelog', '867'),
    exact: true
  },
  {
    path: '/releases/RelNotes_CAPL_3.96-changelog',
    component: ComponentCreator('/releases/RelNotes_CAPL_3.96-changelog', '089'),
    exact: true
  },
  {
    path: '/releases/RelNotes_CAPL_3.97-changelog',
    component: ComponentCreator('/releases/RelNotes_CAPL_3.97-changelog', '787'),
    exact: true
  },
  {
    path: '/releases/RelNotes_CAPL_3.98-changelog',
    component: ComponentCreator('/releases/RelNotes_CAPL_3.98-changelog', '2de'),
    exact: true
  },
  {
    path: '/releases/RelNotes_CAPL_3.99-changelog',
    component: ComponentCreator('/releases/RelNotes_CAPL_3.99-changelog', '59b'),
    exact: true
  },
  {
    path: '/releases/RelNotes_CAPL_4.00-changelog',
    component: ComponentCreator('/releases/RelNotes_CAPL_4.00-changelog', 'c89'),
    exact: true
  },
  {
    path: '/releases/RelNotes_CAPL_4.01-changelog',
    component: ComponentCreator('/releases/RelNotes_CAPL_4.01-changelog', '30e'),
    exact: true
  },
  {
    path: '/releases/RelNotes_CAPL_4.02-changelog',
    component: ComponentCreator('/releases/RelNotes_CAPL_4.02-changelog', '34c'),
    exact: true
  },
  {
    path: '/releases/RelNotes_CAPL_4.03-changelog',
    component: ComponentCreator('/releases/RelNotes_CAPL_4.03-changelog', '165'),
    exact: true
  },
  {
    path: '/releases/RelNotes_CAPL_4.04-changelog',
    component: ComponentCreator('/releases/RelNotes_CAPL_4.04-changelog', '824'),
    exact: true
  },
  {
    path: '/releases/RelNotes_CAPL_4.05-changelog',
    component: ComponentCreator('/releases/RelNotes_CAPL_4.05-changelog', '319'),
    exact: true
  },
  {
    path: '/releases/RelNotes_CAPL_4.06-changelog',
    component: ComponentCreator('/releases/RelNotes_CAPL_4.06-changelog', 'aeb'),
    exact: true
  },
  {
    path: '/releases/RelNotes_CAPL_4.07-changelog',
    component: ComponentCreator('/releases/RelNotes_CAPL_4.07-changelog', '30c'),
    exact: true
  },
  {
    path: '/releases/RelNotes_CAPL_4.08-changelog',
    component: ComponentCreator('/releases/RelNotes_CAPL_4.08-changelog', '6c9'),
    exact: true
  },
  {
    path: '/releases/RelNotes_CAPL_4.09-changelog',
    component: ComponentCreator('/releases/RelNotes_CAPL_4.09-changelog', '843'),
    exact: true
  },
  {
    path: '/releases/RelNotes_CAPL_4.10-changelog',
    component: ComponentCreator('/releases/RelNotes_CAPL_4.10-changelog', 'f64'),
    exact: true
  },
  {
    path: '/releases/RelNotes_CAPL_4.11-changelog',
    component: ComponentCreator('/releases/RelNotes_CAPL_4.11-changelog', 'c91'),
    exact: true
  },
  {
    path: '/releases/RelNotes_CAPL_4.12-changelog',
    component: ComponentCreator('/releases/RelNotes_CAPL_4.12-changelog', '6f3'),
    exact: true
  },
  {
    path: '/releases/RelNotes_CAPL_4.14-changelog',
    component: ComponentCreator('/releases/RelNotes_CAPL_4.14-changelog', 'd96'),
    exact: true
  },
  {
    path: '/releases/RelNotes_PYCAROL_2.55.0-changelog',
    component: ComponentCreator('/releases/RelNotes_PYCAROL_2.55.0-changelog', '136'),
    exact: true
  },
  {
    path: '/releases/tags',
    component: ComponentCreator('/releases/tags', '44e'),
    exact: true
  },
  {
    path: '/releases/tags/releases',
    component: ComponentCreator('/releases/tags/releases', 'd86'),
    exact: true
  },
  {
    path: '/releases/tags/releases/page/2',
    component: ComponentCreator('/releases/tags/releases/page/2', 'c83'),
    exact: true
  },
  {
    path: '/releases/tags/releases/page/3',
    component: ComponentCreator('/releases/tags/releases/page/3', '4ec'),
    exact: true
  },
  {
    path: '/releases/tags/releases/page/4',
    component: ComponentCreator('/releases/tags/releases/page/4', 'ccf'),
    exact: true
  },
  {
    path: '/releases/tags/releases/page/5',
    component: ComponentCreator('/releases/tags/releases/page/5', '5fb'),
    exact: true
  },
  {
    path: '/template',
    component: ComponentCreator('/template', 'd8e'),
    exact: true
  },
  {
    path: '/docs',
    component: ComponentCreator('/docs', '1c1'),
    routes: [
      {
        path: '/docs/carol-app/building-docker-image',
        component: ComponentCreator('/docs/carol-app/building-docker-image', 'bdb'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/carol-app/carol-app-git',
        component: ComponentCreator('/docs/carol-app/carol-app-git', 'de6'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/carol-app/carol-app-instance-type',
        component: ComponentCreator('/docs/carol-app/carol-app-instance-type', '932'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/carol-app/carol-app-logs',
        component: ComponentCreator('/docs/carol-app/carol-app-logs', '7bb'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/carol-app/carol-app-service-account-google',
        component: ComponentCreator('/docs/carol-app/carol-app-service-account-google', 'd51'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/carol-app/carol-app-template',
        component: ComponentCreator('/docs/carol-app/carol-app-template', 'f8d'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/carol-app/getting-started',
        component: ComponentCreator('/docs/carol-app/getting-started', '535'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/carol-app/gpu-usage',
        component: ComponentCreator('/docs/carol-app/gpu-usage', '3ad'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/carol-app/manifest-file',
        component: ComponentCreator('/docs/carol-app/manifest-file', '26d'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/category/airbyte',
        component: ComponentCreator('/docs/category/airbyte', 'c28'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/category/carol-app',
        component: ComponentCreator('/docs/category/carol-app', 'e5b'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/category/configuração-do-2c',
        component: ComponentCreator('/docs/category/configuração-do-2c', 'fbd'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/category/data-ingestion',
        component: ComponentCreator('/docs/category/data-ingestion', 'a80'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/category/data-processing',
        component: ComponentCreator('/docs/category/data-processing', 'e1c'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/category/data-subscription',
        component: ComponentCreator('/docs/category/data-subscription', '820'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/category/faq',
        component: ComponentCreator('/docs/category/faq', '242'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/category/kafka-connect',
        component: ComponentCreator('/docs/category/kafka-connect', '400'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/category/papéis-e-acessos',
        component: ComponentCreator('/docs/category/papéis-e-acessos', '381'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/category/processos-gerais',
        component: ComponentCreator('/docs/category/processos-gerais', '620'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/category/querying-data',
        component: ComponentCreator('/docs/category/querying-data', 'c60'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/data-journey/',
        component: ComponentCreator('/docs/data-journey/', '9f6'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/data-share/',
        component: ComponentCreator('/docs/data-share/', 'd46'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/faq/carol-app',
        component: ComponentCreator('/docs/faq/carol-app', '88a'),
        exact: true,
        sidebar: "faqSidebar"
      },
      {
        path: '/docs/faq/data-ingestion',
        component: ComponentCreator('/docs/faq/data-ingestion', '179'),
        exact: true,
        sidebar: "faqSidebar"
      },
      {
        path: '/docs/faq/data-journey',
        component: ComponentCreator('/docs/faq/data-journey', 'd5d'),
        exact: true,
        sidebar: "faqSidebar"
      },
      {
        path: '/docs/faq/data-privacy',
        component: ComponentCreator('/docs/faq/data-privacy', 'cdf'),
        exact: true,
        sidebar: "faqSidebar"
      },
      {
        path: '/docs/faq/data-processing',
        component: ComponentCreator('/docs/faq/data-processing', 'f44'),
        exact: true,
        sidebar: "faqSidebar"
      },
      {
        path: '/docs/faq/data-share',
        component: ComponentCreator('/docs/faq/data-share', '1c7'),
        exact: true,
        sidebar: "faqSidebar"
      },
      {
        path: '/docs/faq/data-subscription',
        component: ComponentCreator('/docs/faq/data-subscription', '768'),
        exact: true,
        sidebar: "faqSidebar"
      },
      {
        path: '/docs/faq/exporting-data-bq',
        component: ComponentCreator('/docs/faq/exporting-data-bq', '3dc'),
        exact: true,
        sidebar: "faqSidebar"
      },
      {
        path: '/docs/faq/integrations',
        component: ComponentCreator('/docs/faq/integrations', 'e7a'),
        exact: true,
        sidebar: "faqSidebar"
      },
      {
        path: '/docs/faq/migrations',
        component: ComponentCreator('/docs/faq/migrations', '4a7'),
        exact: true,
        sidebar: "faqSidebar"
      },
      {
        path: '/docs/faq/papeis-e-acessos',
        component: ComponentCreator('/docs/faq/papeis-e-acessos', '725'),
        exact: true,
        sidebar: "faqSidebar"
      },
      {
        path: '/docs/faq/provisioning',
        component: ComponentCreator('/docs/faq/provisioning', '832'),
        exact: true,
        sidebar: "faqSidebar"
      },
      {
        path: '/docs/faq/safety',
        component: ComponentCreator('/docs/faq/safety', '933'),
        exact: true,
        sidebar: "faqSidebar"
      },
      {
        path: '/docs/general/alerts-management',
        component: ComponentCreator('/docs/general/alerts-management', 'cd5'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/general/authentication',
        component: ComponentCreator('/docs/general/authentication', 'f99'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/general/billing',
        component: ComponentCreator('/docs/general/billing', 'fb5'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/general/carol-insights-studio',
        component: ComponentCreator('/docs/general/carol-insights-studio', 'de7'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/general/data-sync',
        component: ComponentCreator('/docs/general/data-sync', '01a'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/general/data-validation',
        component: ComponentCreator('/docs/general/data-validation', '1df'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/general/provisioning',
        component: ComponentCreator('/docs/general/provisioning', '8a0'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/general/pycarol',
        component: ComponentCreator('/docs/general/pycarol', 'e2d'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/glossary/',
        component: ComponentCreator('/docs/glossary/', 'c19'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/ingestion/about-2c/2c-monitor-configs',
        component: ComponentCreator('/docs/ingestion/about-2c/2c-monitor-configs', '087'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/ingestion/about-2c/configure-data-base',
        component: ComponentCreator('/docs/ingestion/about-2c/configure-data-base', '588'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/ingestion/about-2c/configure-database-strategies',
        component: ComponentCreator('/docs/ingestion/about-2c/configure-database-strategies', '555'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/ingestion/about-2c/configure-directory',
        component: ComponentCreator('/docs/ingestion/about-2c/configure-directory', 'b56'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/ingestion/about-2c/configure-install-2c',
        component: ComponentCreator('/docs/ingestion/about-2c/configure-install-2c', '0e3'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/ingestion/about-2c/configuring-2c',
        component: ComponentCreator('/docs/ingestion/about-2c/configuring-2c', '75f'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/ingestion/about-2c/configuring-2c-memory',
        component: ComponentCreator('/docs/ingestion/about-2c/configuring-2c-memory', 'd6f'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/ingestion/about-2c/pre-requisites-2c',
        component: ComponentCreator('/docs/ingestion/about-2c/pre-requisites-2c', '090'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/ingestion/about-2c/service-2c-Linux',
        component: ComponentCreator('/docs/ingestion/about-2c/service-2c-Linux', '7d7'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/ingestion/about-2c/service-2c-Windows',
        component: ComponentCreator('/docs/ingestion/about-2c/service-2c-Windows', 'e06'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/ingestion/about-2c/service-install-2c',
        component: ComponentCreator('/docs/ingestion/about-2c/service-install-2c', 'bc8'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/ingestion/Airbyte/about-airbyte',
        component: ComponentCreator('/docs/ingestion/Airbyte/about-airbyte', '8a6'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/ingestion/Airbyte/configuration-airbyte',
        component: ComponentCreator('/docs/ingestion/Airbyte/configuration-airbyte', 'f6c'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/ingestion/Airbyte/deploy-airbyte',
        component: ComponentCreator('/docs/ingestion/Airbyte/deploy-airbyte', 'fb3'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/ingestion/carol-file-loader',
        component: ComponentCreator('/docs/ingestion/carol-file-loader', '23f'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/ingestion/inserting-data',
        component: ComponentCreator('/docs/ingestion/inserting-data', 'c55'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/ingestion/kafka-connect/about-kafka',
        component: ComponentCreator('/docs/ingestion/kafka-connect/about-kafka', '83e'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/ingestion/kafka-connect/carol-kafka-connect',
        component: ComponentCreator('/docs/ingestion/kafka-connect/carol-kafka-connect', 'f81'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/ingestion/kafka-connect/configuration-kafka',
        component: ComponentCreator('/docs/ingestion/kafka-connect/configuration-kafka', 'f9e'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/ingestion/kafka-connect/deploy-kafka',
        component: ComponentCreator('/docs/ingestion/kafka-connect/deploy-kafka', 'dc0'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/ingestion/kafka-connect/usability',
        component: ComponentCreator('/docs/ingestion/kafka-connect/usability', 'e1f'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/ingestion/sending-data',
        component: ComponentCreator('/docs/ingestion/sending-data', 'bd9'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/ingestion/staging-tables',
        component: ComponentCreator('/docs/ingestion/staging-tables', 'ebd'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/intro',
        component: ComponentCreator('/docs/intro', 'aed'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/overall/data-access-level',
        component: ComponentCreator('/docs/overall/data-access-level', '7fe'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/overall/homepage',
        component: ComponentCreator('/docs/overall/homepage', '116'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/overall/iam-integration',
        component: ComponentCreator('/docs/overall/iam-integration', '788'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/overall/orgadmin',
        component: ComponentCreator('/docs/overall/orgadmin', '0ae'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/overall/statuspage',
        component: ComponentCreator('/docs/overall/statuspage', '609'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/overall/swagger',
        component: ComponentCreator('/docs/overall/swagger', 'bf4'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/overall/tenantadmin',
        component: ComponentCreator('/docs/overall/tenantadmin', 'dbc'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/processing/custom-fields',
        component: ComponentCreator('/docs/processing/custom-fields', '87e'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/processing/data-models',
        component: ComponentCreator('/docs/processing/data-models', '0de'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/processing/execution-plan',
        component: ComponentCreator('/docs/processing/execution-plan', '51b'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/processing/explore',
        component: ComponentCreator('/docs/processing/explore', 'fdb'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/processing/optimizations',
        component: ComponentCreator('/docs/processing/optimizations', 'ca9'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/processing/orchestrator',
        component: ComponentCreator('/docs/processing/orchestrator', 'c58'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/processing/pipeline-sequencing',
        component: ComponentCreator('/docs/processing/pipeline-sequencing', 'd05'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/processing/pipelines-manifest',
        component: ComponentCreator('/docs/processing/pipelines-manifest', '808'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/processing/rejection-rules',
        component: ComponentCreator('/docs/processing/rejection-rules', '9ec'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/processing/sql-processing-efficiency',
        component: ComponentCreator('/docs/processing/sql-processing-efficiency', 'b8d'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/processing/vscode-plugin',
        component: ComponentCreator('/docs/processing/vscode-plugin', '390'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/querying-data/data-consumption',
        component: ComponentCreator('/docs/querying-data/data-consumption', 'd4c'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/querying-data/es/',
        component: ComponentCreator('/docs/querying-data/es/', 'ac8'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/querying-data/querying-data-bq',
        component: ComponentCreator('/docs/querying-data/querying-data-bq', '153'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/subscription/',
        component: ComponentCreator('/docs/subscription/', '3ba'),
        exact: true,
        sidebar: "tutorialSidebar"
      }
    ]
  },
  {
    path: '/',
    component: ComponentCreator('/', '182'),
    exact: true
  },
  {
    path: '*',
    component: ComponentCreator('*'),
  },
];
